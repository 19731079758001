import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
import {
  Container,
  Heading,
  TeamsPaper,
  SmallHeading,
  SmallParagraph,
  GrassImage,
} from "../component/UI/styledItems";

const useStyles = makeStyles((theme) => ({
  ourTeams: {
    background: "#F0F0F8",
    position: "relative",
    paddingBottom: "4.5rem",
  },
  teamsButton: {
    cursor: "pointer",
    transition: "0.3s",
    display: "flex",
    alignItems: "center",
    width: "15rem",
    "&:hover": {
      transform: "scale( 1.05 )",
    },
    "& img": {
      width: "2rem",
      marginLeft: "auto",
    },
  },
  teamItem: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "15rem",
  },
  memberImage: {
    width: "100%",
  },
}));

const teamMembers = [
  {
    image: "/teamMembers/golu.jpg",
    name: "Golu Vishwakarma",
    position: "Director",
  },
  {
    image: "/teamMembers/chetan.jpg",
    name: "Chetan Sharma",
    position: "Director",
  },
  {
    image: "/teamMembers/apoorv.jpg",
    name: "Apoorv Singh Chouhan",
    position: "Director",
  },
  {
    image: "/teamMembers/mohan.jpg",
    name: "Mohan Tanwar",
    position: "Director",
  },
  {
    image: "/teamMembers/ashutosh.jpg",
    name: "Ashutosh Singh Rajput",
    position: "Director",
  },
];

const OurTeams = () => {
  const classes = useStyles();

  return (
    <>
      <MetaTags title={"Mobisafe - Our Team"} />
      <AnimatedPage>
        <div className={classes.ourTeams}>
          <Container>
            <Box display="flex" alignItems="center" marginBottom="4.5rem">
              <Heading color="#1C232D" margin="0 auto 0 0">
                Our Team
              </Heading>
              {/* <TeamsPaper
                size="small"
                borderRadius="10px"
                padding="0.5rem 1.5rem"
                className={classes.teamsButton}
              >
                <SmallHeading margin="0" fontSize="2rem" color="#1C232D">
                  Filter
                </SmallHeading>
                <img src={require("../Assets/icons/filter.svg").default} alt="" />
              </TeamsPaper> */}
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fit, minmax( 15rem, 1fr ))"
              margin="0 -0.9rem"
            >
              {teamMembers.map((member, i) => (
                <Box margin="1rem" key={i}>
                  <TeamsPaper
                    className={classes.teamItem}
                    borderRadius="0"
                    margin="0.5rem auto"
                  >
                    <div className={classes.memberImage}>
                      <img
                        style={{ width: "100%" }}
                        src={require("../Assets/teamMembers/dummy.png").default}
                        // src={member.image}
                        alt="Team Member"
                      />
                    </div>
                    <SmallHeading color="#1C232D" margin="1.2rem 0 0.7rem">
                      {/* {member.name} */}
                      {"Member"}
                    </SmallHeading>
                    <SmallParagraph color="#455A64" margin="0 0 1.5rem">
                      {member.position}
                    </SmallParagraph>
                  </TeamsPaper>
                </Box>
              ))}
            </Box>
            <GrassImage />
          </Container>
        </div>
      </AnimatedPage>
    </>
  );
};

export default OurTeams;
