import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const adminSelectors = {};

const generateTokens = createAsyncThunk(
  "admin/generateTokens",
  async ({ numberOfTokens, type }, { extra: api }) => {
    const { data } = await api.generateTokens({ numberOfTokens, type });
    console.log("data", data);
    return data;
  }
);

const getTokensAggregations = createAsyncThunk(
  "admin/getTokensAggregations",
  async (_, { extra: api }) => {
    const { data } = await api.getAdminTokensAggregations();
    return data;
  }
);

const getActivationCodes = createAsyncThunk(
  "admin/getActivationCodes",
  async ({ startAfter, limit, filter, phone, duration }, { extra: api }) => {
    const { data } = await api.getAdminActivationCodes(
      startAfter,
      limit,
      filter,
      phone,
      duration
    );
    return data;
  }
);

const disableToken = createAsyncThunk(
  "admin/disableToken",
  async ({ action, tokenId }, { extra: api }) => {
    const { data } = await api.disableToken(tokenId, action);
    return data;
  }
);

const lockPhoneByAdmin = createAsyncThunk(
  "admin/lockPhoneByAdmin",
  async ({ task, token }, { extra: api }) => {
    const { data } = await api.lockPhoneByAdmin({ task, token });
    return data;
  }
);

const getAdminUserLockUnlock = createAsyncThunk(
  "admin/getAdminUserLockUnlock",
  async ({ startAfter, limit, phone }, { extra: api }) => {
    const { data } = await api.getAdminUserLockUnlock(startAfter, limit, phone);
    return data;
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: {},
  extraReducers: {},
});

export default adminSlice.reducer;

const adminActions = { ...adminSlice.actions };

export {
  adminActions,
  adminSelectors,
  generateTokens,
  getTokensAggregations,
  getActivationCodes,
  lockPhoneByAdmin,
  getAdminUserLockUnlock,
  disableToken,
};
