import React, { useState, useRef, useMemo } from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { AnimatedPage, Carousel } from "../component/UI";
import {
  Heading,
  Paragraph,
  Button,
  TeamsPaper,
  SmallHeading,
  SmallParagraph,
  Container,
  GrassImage,
} from "../component/UI/styledItems";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Slider from "react-slick";

import ParticlesBg from "particles-bg";
import MetaTags from "../component/MetaTags";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  blobTop: {
    position: "absolute",
    width: "25rem",
    top: "-5rem",
    left: "0rem",
  },
  blobText: {
    color: "#A2D196",
    fontWeight: 800,
    fontSize: "6.5rem",
    position: "absolute",
    top: "-5rem",
    left: "10rem",
    zIndex: 100,
    [theme.breakpoints.down(800)]: {
      fontSize: "6rem",
      top: "-4.5rem",
    },
    [theme.breakpoints.down(670)]: {
      fontSize: "5rem",
      top: "-3rem",
      left: "calc( 5rem + 10vw )",
    },
  },
  carItems: {
    minHeight: "42rem",
    height: "calc( 100vh - 8rem )",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(670)]: {
    justifyContent: "center",
    },
  },
  textDiv: {
    width: "30rem",
    position: "relative",
    zIndex: 10,
    [theme.breakpoints.down(1000)]: {
      width: "37rem",
    },
    [theme.breakpoints.down(670)]: {
      textAlign: "center",
    },
  },
  marginTop: {
    marginTop: "3.5rem",
    [theme.breakpoints.down(670)]: {
      marginTop: "-150px",
    },
  },
  heading: {
    margin: "0 0 1.5rem",
    textDecoration: "none",
  },
  rightImage: {
    width: "23rem",
    margin: "auto",
    position: "relative",
    zIndex: 5,
    [theme.breakpoints.down(1000)]: {
      display: "none !important",
    },
  },
  HomeRightImage: {
    // width: "23rem",
    // margin: "auto",
    // position: "relative",
    // zIndex: 5,
    [theme.breakpoints.down(1000)]: {
      display: "none !important",
    },
  },
  blobBottom: {
    position: "absolute",
    width: "14rem",
    bottom: "4rem",
    right: "0",
    zIndex: 1,
    [theme.breakpoints.down(670)]: {
      width: "14rem",
      bottom: "-8rem",
      right: "0",
      transform: "rotate(90deg)",
    },
  },
  aboutUs: {
    marginTop: "-4px",
    background: "#012147",
    position: "relative",
  },
  aboutUsContainer: {
    display: "flex",
    alignItems: "center",
  },
  ourProductsGrid: {
    display: "grid",
    gridTemplateColumns: "3.5fr 3fr 5.5fr",
    columnGap: "5rem",
    marginTop: "3rem",
    [theme.breakpoints.down(1200)]: {
      gridTemplateColumns: "4fr 8fr",
    },
    [theme.breakpoints.down(1000)]: {
      display: "block",
    },
  },
  productItem: {
    lineHeight: 3.1,
    width: "14.5rem",
    textAlign: "center",
    borderRadius: 0,
    fontWeight: 600,
    fontSize: "1.5rem",
    margin: "1.2rem 0",
    position: "relative",
    color: "#272727",
    transition: "0.5s",
    cursor: "pointer",
    "&::after": {
      content: "''",
      width: 0,
      height: 0,
      borderTop: "11px solid transparent",
      borderBottom: "11px solid transparent",
      borderLeft: "18px solid #1C232D",
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translate(100%, -50%)",
      opacity: 0,
      transition: "0.5s",
      transitionTimingFunction: "ease-out",
    },
    [theme.breakpoints.down(1455)]: {
      width: "13rem",
      fontSize: "1.2rem",
    },
  },
  productItemSelected: {
    marginLeft: "2rem",
    background: "#1C232D",
    color: "white",
    "&::after": {
      opacity: 100,
    },
  },
  productImage: {
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
  },
  productTextDiv: {
    display: "flex",
    flexDirection: "column",
    color: "#272727",
    padding: "1rem 0",
    [theme.breakpoints.down(1000)]: {
      "& h4": {
        color: "#515151",
        marginBottom: "0.7rem",
      },
      "& p": {
        color: "#272727",
      },
    },
  },
  arrowDiv: {
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-start",
    marginTop: 0,
    "& svg": {
      fontSize: "1rem",
      color: "#272727",
    },
  },
  ourTeam: {
    background: "#F0F0F8",
    paddingBottom: "5rem",
    position: "relative",
  },
  teamsButton: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      transform: "scale( 1.05 )",
    },
    "& svg": {
      fontSize: "1rem",
      margin: "auto",
      [theme.breakpoints.down(400)]: {
        fontSize: "0.85rem",
      },
    },
    [theme.breakpoints.down(400)]: {
      width: "2rem",
      height: "2rem",
    },
  },
  teamItem: {
    maxWidth: "90%",
    display: "flex",
    flexDirection: "column",
    // border:'1px solid red',
    // width:"300px"
  },
  memberImage: {
    width: "7rem",
    borderRadius: "100%",
    margin: "0 auto 1.5rem",
    overflow: "hidden",
  },
  mediaHandles: {
    margin: "0 auto",
    transition: "transform 0.3s",
    "& > div": {
      overflow: "hidden",
      [theme.breakpoints.down(600)]: {
        width: "2.5rem",
        height: "2.5rem",
      },
      [theme.breakpoints.down(400)]: {
        width: "2rem",
        height: "2rem",
      },
      "& > img": {
        color: "#1C232D",
        margin: "auto",
        height: "40%",
      },
    },
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "42px",
    lineHeight: "63px",
    letterSpacing: "0.04em",
    textTransform: "capitalize",
    color: "#0D1E27",
    textAlign: 'center'
  },
  card: {
    width: "274px",
    height: "241px",
    background: "#FFFFFF",
    boxShadow: "0px 8px 23px rgba(0, 0, 0, 0.07)",
    margin: "15px 0",
    padding: '10px'
  },
  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "25px",
    lineHeight: "37px",
    letterSpacing: "0.04em",
    textTransform: "capitalize",
    color: "#0D1E27"
  },
  para: {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "17px",
    lineHeight: "25px",
    textTransform: "capitalize",
    color: "#0D1E27"
  },
  cardcontainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down(670)]: {
      justifyContent: "center",
    },
  },
  features:{
    display:'flex',
    alignItems:'center',
    [theme.breakpoints.down(670)]: {
     flexWrap:'wrap',
     justifyContent:'center'
    },
  },
  featuresimg:{
    [theme.breakpoints.down(670)]: {
      display:'none'
    },
  },
  dbutton:{
    // border:'1px solid red',
    [theme.breakpoints.down(670)]: {
      // display:'none'
      margin:'auto 50px'
    },
  }
}));

const settings = {
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  speed: 500,
  swipeToSlide: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const carouselData = [
  {
    // slideNum: "01",
    heading: "Anti-Theft",
    description:
      "As the name says, it is anti-theft software. Our main goal is to protect the data from being misused.",
  },
  {
    // slideNum: "02",
    heading: "Human Safety",
    description:
      "Safety is the necessary point to which everybody should pay attention. In the present situation, being alone is dangerous.",
  },
  {
    // slideNum: "03",
    heading: "Retailer App",
    description:
      "We want to allow those retailers and small businesses who want to invest and earn a good amount of profit out of it through our company.",
  },
];

const teamMembers = [
  {
    image: require("../Assets/teamMembers/golu.jpg").default,
    name: "Golu Vishwakarma",
    position: "Director",
  },
  {
    image: require("../Assets/teamMembers/chetan.jpg").default,
    name: "Chetan Sharma",
    position: "Director",
  },
  {
    image: require("../Assets/teamMembers/apoorv.jpg").default,
    name: "Apoorv Singh Chouhan",
    position: "Director",
  },
  {
    image: require("../Assets/teamMembers/mohan.jpg").default,
    name: "Mohan Tanwar",
    position: "Director",
  },
  {
    image: require("../Assets/teamMembers/ashutosh.jpg").default,
    name: "Ashutosh Singh Rajput",
    position: "Director",
  },
];
const feautures = [
  // {
  //     image: require("../Assets/features/Subtract.png").default,
  //     title: "Live ",
  //     description: " test",
  // },
  {
    image: require("../Assets/features/Subtract.png").default,
    title: "Live Location",
    description: "Get a live location update of your mobile in case of emergency or mobile loss.",
  },
  {
    image: require("../Assets/features/simcard.png").default,
    title: "Sim card Lock ",
    description: " Lock your mobile whenever someone tries to remove your SIM",
  },
  {
    image: require("../Assets/features/women.png").default,
    title: "Women Safety ",
    description: " Photo link and location link notification will receive via SMS in case of emergency.",
  },
  {
    image: require("../Assets/features/child.png").default,
    title: "Child Activity ",
    description: " provides you with a detailed summary of the activities for your child.",
  },
  {
    image: require("../Assets/features/lock.png").default,
    title: "Mobile Protection ",
    description: " Disallow intruder to uninstall application from your mobile.",
  },
  {
    image: require("../Assets/features/usb.png").default,
    title: "USB Protection ",
    description: " provides you usb protection to secure your mobile’s personal data from theft.",
  },
];
const Home = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:1000px)");

  const [productItem, setProductItem] = useState(0);

  const productItemsArr = useMemo(
    () => [
      {
        title: "Anti-Theft",
        description:
          "As the name says, it is anti-theft software. Our main goal is to protect the data from being misused. In today's world, it is hard for any of us to trust anybody, especially when it comes to our sensitive data.",
        selected: productItem === 0,
        link: "/products#anti-theft",
        onClick: () => {
          if (productItem !== 0) setProductItem(0);
        },
      },
      {
        title: "Human Safety",
        description:
          "Safety is the necessary point to which everybody should pay attention. In the present situation, being alone is dangerous, especially when it comes to females.",
        selected: productItem === 1,
        link: "/products#human-safety",
        onClick: () => {
          if (productItem !== 1) setProductItem(1);
        },
      },
      {
        title: "Retailer App",
        description:
          "We want to allow those retailers and small businesses who want to invest and earn a good amount of profit out of it through our company. It is never too late to start investing.",
        selected: productItem === 2,
        link: "products#retailer-app",
        onClick: () => {
          if (productItem !== 2) setProductItem(2);
        },
      },
    ],
    [productItem]
  );

  const sliderRef = useRef();

  function next() {
    if (sliderRef.current) sliderRef.current.slickNext();
  }
  function previous() {
    if (sliderRef.current) sliderRef.current.slickPrev();
  }

  return (
    <>
      <MetaTags title={"Home"} />
      
    </>
  );
};

export default Home;
