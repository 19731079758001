import React from "react";
import { motion, useCycle, AnimatePresence } from "framer-motion";
import MenuToggle from "./ToggleButton";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import DropLink from "../DropLink";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, logout } from "../../../../application/reducers/authSlice";

const useStyles = makeStyles({
  link: {
    color: "white",
    textDecoration: "none",
    transition: "color 0.5s",
    fontWeight: 500,
    margin: "0 1rem",
    "&:hover": {
      color: "#58B63A",
    },
  },
  activeLink: {
    color: "#58B63A",
  },
  button: {
    margin: "0 0.4rem",
    padding: "0.75rem 0",
    textAlign: "center",
    width: "7rem",
    borderRadius: "5px",
    textDecoration: "none",
    transition: "background 0.5s, color 0.5s",
    fontWeight: 500,
  },

  signin: {
    color: "white",
    border: "1px solid #58B63A",
    background: "#58B63A",
  },

  signup: {
    color: "white",
    border: "1px solid white",
    background: "transparent",
    "&:hover": {
      background: "#58B63A",
      borderColor: "#58B63A",
    },
  },
  activeLinkButton: {
    background: "#58B63A",
    borderColor: "#58B63A",
  },
  nav: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    width: "300px",
    overflow: "auto",
    zIndex: 100,
    background: "rgba(0, 0, 0, 0.5)",
    "& ul,li": {
      margin: 0,
      padding: 0,
    },
    "& ul": {
      padding: "25px",
      position: "relative",
      top: "100px",
      margin: "auto",
      width: "230px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& li": {
      listStyle: "none",
      marginBottom: "2rem",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    "&::-webkit-scrollbar": {
      width: 7,
      height: 7,
    },

    "&::-webkit-scrollbar-track": {
      background: "rgb(138, 138, 138)",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "red",
      borderRadius: 1,
    },

    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "white",
    },
  },
  background: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    width: "300px",
    background: "#1C232D",
    boxShadow: "rgb(0, 0, 0) 1px 0px 11px 0px",
  },
});

const navigationVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.01, staggerDirection: -1 },
  },
};

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: -50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
      duration: 0.02,
    },
  },
};

const links = [
  // {
  //   link: "/",
  //   name: "Home",
  // },
  // {
  //   link: "/products",
  //   name: "Products",
  // },
  // {
  //   link: "/career",
  //   name: "Career",
  // },
  // // {
  // //   link: "/clients",
  // //   name: "Clients",
  // // },
  // {
  //   link: "/contact-us",
  //   name: "Contact Us",
  // },
  // {
  //   link: "/profile",
  //   name: "Profile",
  // },
  // {
  //   link: "/faq",
  //   name: "FAQ",
  // },
  // {
  //   link: "/",
  //   name: "Home",
  // },
  // {
  //   link: "/products",
  //   name: "Products",
  // },
  // {
  //   link: "/features",
  //   name: "Featuers",
  // },

  // {
  //   link: "/career",
  //   name: "Career",
  // },

  // {
  //   link: "/contact-us",
  //   name: "Contact Us",
  // },
];

const NavItem = ({ children }) => {
  return (
    <motion.li
      variants={itemVariants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      {children}
    </motion.li>
  );
};

const Navigation = ({ close }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authState = useSelector(getAuth);

  return (
    <motion.ul variants={navigationVariants}>
      {links.map((link, i) => (
        <NavItem key={i}>
          <NavLink
            key={i}
            className={classes.link}
            to={link.link}
            activeClassName={classes.activeLink}
            exact
            onClick={() => close()}
          >
            {link.name}
          </NavLink>
        </NavItem>
      ))}
      <NavItem>
        {/* <DropLink
          className={classes.link}
          links={[
            {
              link: "/about-us",
              name: "About Us",
            },
            {
              link: "/our-team",
              name: "Our Team",
            },
          ]}
          activeClassName={classes.activeLink}
          onClick={() => close()}
        >
          Company
        </DropLink> */}
      </NavItem>
      {!authState.isAuth ? (null) : (
        <NavLink
          className={classes.link}
          to="/profile"
          onClick={() => close()}
          activeClassName={classes.activeLink}
          style={{marginBottom:'25px'}}
        >
          Profile
        </NavLink>
      )}
      {authState.isAuth ? (
        <NavItem>
          <p
            className={`${classes.button} ${classes.signup}`}
            onClick={() => {
              dispatch(logout());
              close();
            }}
          >
            Log Out
          </p>
        </NavItem>
      ) : (
        <NavItem>
          <NavLink
            className={`${classes.button} ${classes.signin}`}
            to="/login"
            onClick={() => close()}
          >
            Log In
          </NavLink>
        </NavItem>
      )}
    </motion.ul>
  );
};

const SideDrawer = () => {
  const classes = useStyles();
  const [isOpen, toggleOpen] = useCycle(false, true);

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: "circle(0px at 300px 0px)",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <motion.div initial={false} animate={isOpen ? "open" : "closed"}>
      <MenuToggle isOpen={isOpen} toggle={() => toggleOpen()} />

      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="unique"
            initial="closed"
            animate="open"
            exit="closed"
          >
            <motion.div
              style={{
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 300,
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 100,
              }}
              onClick={() => toggleOpen()}
            ></motion.div>
            <nav custom={500} className={classes.nav}>
              <motion.div className={classes.background} variants={sidebar} />
              <Navigation close={() => toggleOpen()} />
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default SideDrawer;
