import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import {
  Container,
  GrassImage,
  Heading,
  SmallParagraph,
} from "../component/UI/styledItems";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { AnimatedPage, Input, Textarea } from "../component/UI";
import MetaTags from "../component/MetaTags";
import { Formik } from "formik";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  contactUs: {
    paddingBottom: "6rem",
    position: "relative",
    background: "#F0F0F8",
  },
  contactGrid: {
    display: "grid",
    gridTemplateColumns: "8fr 3fr",
    columnGap: "5rem",
    [theme.breakpoints.down(1025)]: {
      gridTemplateColumns: "1fr",
    },
  },

  getInTouch: {
    border: "none",
    fontSize: "1.85rem",
    zIndex: 1,
    margin: "2.5rem 0 2rem",
    position: "relative",

    [theme.breakpoints.down(670)]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "1.4rem",
    },

    "& span:first-child": {
      position: "relative",
      zIndex: 1,
    },
    "& span:last-child": {
      position: "absolute",
      width: "100%",
      height: 15,
      background: "#FFD234",
      left: 0,
      bottom: 5,
    },
  },
  textboxGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "2rem",
    rowGap: "2rem",
    [theme.breakpoints.down(700)]: {
      gridTemplateColumns: "1fr",
    },
  },
  spanColumn: {
    gridColumn: "1 / 3",
    [theme.breakpoints.down(700)]: {
      gridColumn: "1 / 1",
    },
  },
  sendButton: {
    background: "#F0F0F8",
    boxShadow: "-3px -3px 6px #FFFFFF, 3px 3px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: 6,
    padding: "0.5rem 3rem",
    fontWeight: 500,
    fontSize: "1rem",
    fontFamily: "inherit",
    textTransform: "none",
    color: "#1C232D",
  },
  contactDiv: {
    paddingTop: "0.5rem",
    color: "#1C232D",
    "& > div": {
      display: "grid",
      gridTemplateColumns: "1fr 11fr",
      columnGap: "1rem",
      marginTop: "0.8rem",
    },
    "& h4": {
      fontSize: "1.2rem",
      fontWeight: 700,
      margin: 0,
    },
    "& p": {
      fontSize: "1.1rem",
      marginTop: "-3px",
    },
    "& div~h4": {
      marginTop: "1.7rem",
    },
    [theme.breakpoints.down(1025)]: {
      display: "none",
    },
  },
}));

const initialValues = {
  name: "",
  subject: "",
  message: "",
  mobile: "",
};

const schema = yup.object({
  name: yup.string().required().label("Name"),
  mobile: yup
    .string()
    .required()
    .matches(/[6789]\d{9}/, "Not a valid Mobile Number")
    .label("Mobile Number"),
  subject: yup.string().required().label("Subject"),
  message: yup.string().required().label("Message"),
});

const ContactUs = () => {
  const classes = useStyles();

  const onSubmit = (values) => {
    const mailTo = "corporate@moreandhigh.com";
    const subject = values.subject;
    const body = `Name: ${values.name}
    Mobile Number: ${values.mobile}
    Message: ${values.message}`;

    window.open(`mailto:${mailTo}?subject=${subject}&body=${body}`);
  };

  return (
    <>
      <MetaTags title={"Mobisafe - Contact Us"} />
      <AnimatedPage>
        <div className={classes.contactUs}>
          <Container>
            <div>
              <Heading margin="0" color="#1C232D">
                Contact Us
              </Heading>
            </div>
            <Heading color="#1C232D" className={classes.getInTouch}>
              <span>Get in touch</span>
              <span></span>
            </Heading>
            <Box className={classes.contactGrid}>
              <Formik
                initialValues={initialValues}
                validationSchema={schema}
                validateOnChange={false}
                validateOnBlur
                onSubmit={onSubmit}
              >
                {({
                  values,
                  errors,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                }) => (
                  <form>
                    <Box className={classes.textboxGrid}>
                      <Input
                        label="Your Name"
                        value={values["name"]}
                        name={"name"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors["name"]}
                        helperText={errors["name"]}
                      />
                      <Input
                        label="Mobile Number"
                        value={values["mobile"]}
                        name={"mobile"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors["mobile"]}
                        helperText={errors["mobile"]}
                      />
                      <Input
                        className={classes.spanColumn}
                        label="Subject"
                        value={values["subject"]}
                        name={"subject"}
                        subject={"subject"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors["subject"]}
                        helperText={errors["subject"]}
                      />
                      <Textarea
                        className={classes.spanColumn}
                        type="textarea"
                        label="Message"
                        value={values["message"]}
                        name={"message"}
                        message={"message"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors["message"]}
                        helperText={errors["message"]}
                      />
                      <div className={classes.spanColumn}>
                        <Button
                          classes={{ root: classes.sendButton }}
                          onClick={handleSubmit}
                        >
                          Send
                        </Button>
                      </div>
                    </Box>
                  </form>
                )}
              </Formik>

              <Box className={classes.contactDiv}>
                <h4>Phone:</h4>
                <Box>
                  <PhoneIcon />
                  <SmallParagraph margin="0">
                    <a
                      style={{ textDecoration: "none", color: "inherit" }}
                      href="tel:+91 9109203220"
                    >
                      +91 9109203220
                    </a>
                  </SmallParagraph>
                </Box>
                <h4>E-Mail:</h4>
                <Box>
                  <EmailIcon />
                  <SmallParagraph margin="0">
                    <a
                      style={{ textDecoration: "none", color: "inherit" }}
                      href="mailto:corporate@moreandhigh.com"
                    >
                      corporate@moreandhigh.com
                    </a>
                  </SmallParagraph>
                </Box>
                <h4>Address:</h4>
                <Box>
                  <LocationOnIcon />
                  <SmallParagraph margin="0">
                    OFFICE NO 214 KRISHNA BUSINESS CENTER SCHEME NO 54 PU-4
                    INDORE
                  </SmallParagraph>
                </Box>
              </Box>
            </Box>
          </Container>
          {/* <GrassImage /> */}
        </div>
      </AnimatedPage>
    </>
  );
};

export default ContactUs;
