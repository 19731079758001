import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container } from "../component/UI/styledItems";
import FormikForm from "../component/FormikForm";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAuthUI, login } from "../../application/reducers/authSlice";
import { useHistory } from "react-router-dom";

const loginForm = [
  {
    label: "Your Phone Number",
    name: "phone",
    type: "text",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
  },
];

const LOGIN_INITIAL_VALUES = {
  phone: "",
  password: "",
};

const loginSchema = yup.object({
  phone: yup
    .string()
    .required()
    .matches(/^[6789]\d{9}$/, "Not a valid Mobile Number")
    .label("Mobile Number"),
  password: yup.string().required().label("Password"),
});

const useStyles = makeStyles((theme) => ({
  Login: {
    background: "linear-gradient(180deg, #607AED 0%, #63A5F5 100%)",
    position: "relative",
    paddingBottom: "1rem",
    minHeight: "90vh",
    padding: "1rem",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "90%",
    padding: "2rem",
    background: "#F0F0F8",
    borderRadius: "1.25rem",
    [theme.breakpoints.down(700)]: {
      padding: "1rem",
    },
  },
  heading: {
    color: "#0D1E27",
    fontSize: "2rem",
    fontWeight: 800,
    marginBottom: "2rem",
    textAlign: "center",
    [theme.breakpoints.down("1200")]: {
      fontSize: "calc(1.4rem + 0.5vw)",
    },
    [theme.breakpoints.down("780")]: {
      fontSize: "1.42rem",
    },
  },
}));

export default function Login() {
  const classes = useStyles();

  const authUiState = useSelector(getAuthUI);

  const history = useHistory();
  const dispatch = useDispatch();

  const submitLogin = async (values) => {
    const data = await dispatch(login({ ...values }));

    if (!data.payload) return;

    if (data.payload.role === "admin") {
      history.push("/admin");
    } else {
      history.push("/profile");
    }
  };

  return (
    <>
      <MetaTags title={"Login"} />
      <AnimatedPage>
        <div className={classes.Login}>
          <div className={classes.formContainer}>
            <h2 className={classes.heading}>Log In</h2>
            <FormikForm
              label="Log In"
              form={loginForm}
              initialValues={LOGIN_INITIAL_VALUES}
              schema={loginSchema}
              onSubmit={submitLogin}
              loading={authUiState.login.loading}
              errorMsg={authUiState.login.error}
            />
          </div>
        </div>
      </AnimatedPage>
    </>
  );
}
