import { makeStyles } from "@material-ui/core";
import React from "react";
import {
  Container,
  GrassImage,
  Heading,
  Paragraph,
} from "../component/UI/styledItems";
import MetaTags from "../component/MetaTags";
import { AnimatedPage } from "../component/UI";

const useStyles = makeStyles((theme) => ({
  aboutUs: {
    position: "relative",
  },
  aboutUsCont: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "5rem",
    rowGap: "5rem",
    paddingBottom: "12rem",
    [theme.breakpoints.down(1200)]: {
      gridTemplateColumns: "1fr",
      rowGap: "3rem",
    },
    [theme.breakpoints.down(670)]: {
      textAlign: "center",
    },
  },
  lineHeading: {
    display: "flex",
    alignItems: "center",
    color: "#1C232D",
    margin: "0 0 1.5rem",
    [theme.breakpoints.down(670)]: {
      justifyContent: "center",
    },
  },
  rightImage: {
    // width: "25rem",
    minWidth: 0,
    margin: "auto",
    position: "relative",
    [theme.breakpoints.down(660)]: {
      display: "none",
    },
  },
  hr: {
    margin: 0,
    marginRight: "0.5rem",
    height: "5px",
    background: "#1C232D",
    width: "2.7rem",
    borderRadius: "3px",
  },
}));

const AboutUs = () => {
  const classes = useStyles();

  return (
    <>
      <MetaTags title={"Mobisafe - About Us"} />
      <AnimatedPage>
        <div className={classes.aboutUs}>
          <Container className={classes.aboutUsCont}>
            <div>
              <Heading color="#1C232D" margin="0 0 1.5rem">
                About Us
              </Heading>
              <Paragraph color="#455A64" margin="0 0 1rem">
                More and High Technology Private Limited, which Yoddha produces.
                Our main goal is to protect every human being and the data being
                used by them. In today's world, it is tough to keep our data
                safe. Our phone is used for communication and is also a locker
                for our sensitive data. Sometimes, unfortunately, it happens
                that an intruder steals the key of the vault without our notice.
                It is our prime duty to keep our data safe. We have done our
                part for your data safety. We have built an application to keep
                an eye on our loved ones when they are in trouble. We even
                provide software in bulk and sell its key.
              </Paragraph>
              <Paragraph color="#455A64" margin="0 0 2rem">
                For our devices, we need safety as well as security. Many of us
                have this big misconception that safety and security are the
                same, but it is not so. Safety is referred to the conditions
                likely to cause harm to individuals, organizations, or assets.
                One knows the risk factor and has control over it. Security is
                the protection of individuals, organizations, or purchases from
                external threats which are likely to harm. Security focuses on
                external troubles and does not let them enter the premises.
              </Paragraph>
            </div>
            <div className={classes.rightImage}>
              <img
                // style={{ width: "100%" }}
                src={require("../Assets/newabout.png").default}
                alt=""
              />
            </div>
            <div>
              <div className={classes.lineHeading}>
                <hr className={classes.hr} />
                <Heading margin="0">Mission</Heading>
              </div>
              <Paragraph color="#455A64" margin="0 0 2rem">
                Our mission is to employ every individual in India. More and
                High Technology Private Limited aims to contribute to the aim of
                India in making our country Atma Nirbhar Bharat. There are many
                talented and educated people out there who are suffering from
                unemployment. They have finished their studies in lockdown but
                couldn't get the jobs that match their qualifications. Many
                qualified and skilled people are sitting ideally at home. Due to
                unemployment, there are chances that youth might go in the wrong
                direction; hence we want to allow youth to prove themselves and
                contribute to India's growth. Many of us have to shut our
                businesses due to lockdown and couldn't start again. We provide
                them different plans like high returns in small investments when
                they can do business. We want to protect everyone and their data
                from being misused through digital security.
              </Paragraph>
            </div>
            <div>
              <div className={classes.lineHeading}>
                <hr className={classes.hr} />
                <Heading margin="0">Vision</Heading>
              </div>
              <Paragraph color="#455A64" margin="0">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Consectetur, vitae! Nostrum ullam fugit facilis! Voluptatibus,
                aut veniam fugit sit doloribus explicabo aliquid labore, vel
                fugiat sint asperiores aliquam cupiditate repellus.
              </Paragraph>
            </div>
            {/* <GrassImage /> */}
          </Container>
        </div>
      </AnimatedPage>
    </>
  );
};

export default AboutUs;
