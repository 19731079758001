import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    height: "auto",
    background: "#272727",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    padding: "1rem",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Box sx={{ width: '100%', height: 'auto', background: '#272727', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Mobisafe © 2021</Typography>
      </Box>
    </div>
  );
};

export default Footer;
